import $ from 'jquery';

$(document).ready(function () {
  acfExampleBlock();
});

function acfExampleBlock() {
  $('.watch-video').each(function () {});
  $('.fancybox-slide').click(function () {
    console.log('click');
    $(this).removeClass('is-active');
    $('.watch-video__video iframe').attr('src', $('iframe').attr('src'));
    $('body').css('overflow', 'auto');
  });
}
