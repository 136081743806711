// Import everything from autoload folder

import './autoload/ada-compliance.js'; import './autoload/foundation.js'; // eslint-disable-line
// Import local dependencies
import './plugins/lazyload';
import './plugins/modernizr.min';
import 'slick-carousel';
import 'jquery-match-height';
import objectFitImages from 'object-fit-images';
import '@fancyapps/fancybox/dist/jquery.fancybox.min';
// import { jarallax, jarallaxElement } from 'jarallax';
import ScrollOut from 'scroll-out';

/**
 * Import scripts from Custom Divi blocks
 */
// eslint-disable-next-line import/no-unresolved
//import '../blocks/divi/example-block/index.js';

/**
 * Import scripts from Custom Elementor widgets
 */
// eslint-disable-next-line import/no-unresolved
//import '../blocks/elementor/example-widget/index.js';

/**
 * Import scripts from Custom ACF Gutenberg blocks
 */
// eslint-disable-next-line import/no-unresolved
import '../blocks/gutenberg/acf-accordion/index.js'; import '../blocks/gutenberg/acf-example-block/index.js'; import '../blocks/gutenberg/acf-section-block/index.js'; import '../blocks/gutenberg/acf-section-blog-post-list/index.js'; import '../blocks/gutenberg/acf-section-map/index.js'; import '../blocks/gutenberg/acf-section-repeater/index.js'; import '../blocks/gutenberg/acf-watch-video/index.js';

/**
 * Init foundation
 */
$(document).foundation();

/**
 * Fit slide video background to video holder
 */
function resizeVideo() {
  let $holder = $('.videoHolder');
  $holder.each(function () {
    let $that = $(this);
    let ratio = $that.data('ratio') ? $that.data('ratio') : '16:9';
    let width = parseFloat(ratio.split(':')[0]);
    let height = parseFloat(ratio.split(':')[1]);
    $that.find('.video').each(function () {
      if ($that.width() / width > $that.height() / height) {
        $(this).css({
          width: '100%',
          height: 'auto',
        });
      } else {
        $(this).css({
          width: ($that.height() * width) / height,
          height: '100%',
        });
      }
    });
  });
}

// $(document).on('click', '.fancybox-button', function () {
//   console.log('click');
// $(this).removeClass('is-active');
// $('.watch-video__video iframe').attr('src', $('iframe').attr('src'));
// $('body').css('overflow', 'auto');
// });
$(document).ready(function () {
  ScrollOut({
    offset: function () {
      return window.innerHeight - 300;
    },
    threshold: 0.3,
    once: true,
    onShown: function (element) {
      if ($(element).is('.ease-order')) {
        let $items = $(element).find('.ease-order__item');
        // let defaltTransition = 0;
        $items.each(function (i) {
          let $this = $(this);
          $this.attr('data-scroll', '');
          window.setTimeout(function () {
            $this.attr('data-scroll', 'in');
          }, 300 * i);
        });
      }
    },
  });
  $('.repeater__inner').each(function () {
    let defaultDelay = 0;
    let iteams = $(this).find('.ease-order__item');
    iteams.each(function () {
      // console.log($(this));
      defaultDelay = defaultDelay + 0.5;
      $(this).css('transition-delay', defaultDelay + 's');
    });
  });
  // let numberHr = 0;
  // $('.ease-order__item').each(function () {
  //   $(this).addClass('number-hr');
  // });
  if (
    $('.hero-section').length == 0 &&
    $('.home-slider').length == 0 &&
    $('.landing-hero').length == 0
  ) {
    $('.header').addClass('bright-header');
  }
});

/**
 * Scripts which runs after DOM load
 */
$(document).on('ready', function () {
  /**
   * Make elements equal height
   */
  $('.matchHeight').matchHeight();

  /**
   * IE Object-fit cover polyfill
   */
  if ($('.of-cover').length) {
    objectFitImages('.of-cover');
  }

  $('#srch-btn').on('click', function () {
    $('#srch-form').toggleClass('hide');
    document.getElementById('search__input').focus();
  });

  /**
   * Add fancybox to images
   */
  // $('.gallery-item')
  //   .find('a[href$="jpg"], a[href$="png"], a[href$="gif"]')
  //   .attr('rel', 'gallery')
  //   .attr('data-fancybox', 'gallery');
  // $(
  //   '.fancybox, a[rel*="album"], a[href$="jpg"], a[href$="png"], a[href$="gif"]'
  // ).fancybox({
  //   minHeight: 0,
  //   helpers: {
  //     overlay: {
  //       locked: false,
  //     },
  //   },
  // });

  /**
   * Init parallax
   */
  // jarallaxElement();
  // jarallax(document.querySelectorAll('.jarallax'), {
  //   speed: 0.5,
  // });
  // console.log('click');

  /**
   * Detect element appearance in viewport
   */
  // ScrollOut({
  //   offset: function () {
  //     return window.innerHeight - 300;
  //   },
  //   threshold: 0.3,
  //   once: false,
  //   onShown: function (element) {
  //     if ($(element).is('.ease-order')) {
  //       $(element)
  //         .find('.ease-order__item')
  //         .each(function (i) {
  //           let $this = $(this);
  //           $(this).attr('data-scroll', '');
  //           window.setTimeout(function () {
  //             $this.attr('data-scroll', 'in');
  //           }, 300 * i);
  //         });
  //     }
  //   },
  // });

  /**
   * Remove placeholder on click
   */
  const removeFieldPlaceholder = () => {
    $('input, textarea').each((i, el) => {
      $(el)
        .data('holder', $(el).attr('placeholder'))
        .on('focusin', () => {
          $(el).attr('placeholder', '');
        })
        .on('focusout', () => {
          $(el).attr('placeholder', $(el).data('holder'));
        });
    });
  };

  removeFieldPlaceholder();

  $(document).on('gform_post_render', () => {
    removeFieldPlaceholder();
  });

  /**
   * Scroll to Gravity Form confirmation message after form submit
   */
  $(document).on('gform_confirmation_loaded', function (event, formId) {
    let $target = $('#gform_confirmation_wrapper_' + formId);
    if ($target.length) {
      $('html, body').animate({ scrollTop: $target.offset().top - 50 }, 500);
      return false;
    }
  });

  /**
   * Hide gravity forms required field message on data input
   */
  $('body').on(
    'change keyup',
    '.gfield input, .gfield textarea, .gfield select',
    function () {
      let $field = $(this).closest('.gfield');
      if ($field.hasClass('gfield_error') && $(this).val().length) {
        $field.find('.validation_message').hide();
      } else if ($field.hasClass('gfield_error') && !$(this).val().length) {
        $field.find('.validation_message').show();
      }
    }
  );

  /**
   * Add `is-active` class to menu-icon button on Responsive menu toggle
   * And remove it on breakpoint change
   */
  $(window)
    .on('toggled.zf.responsiveToggle', function () {
      $('.menu-icon').toggleClass('is-active');
    })
    .on('changed.zf.mediaquery', function () {
      $('.menu-icon').removeClass('is-active');
    });

  /**
   * Close responsive menu on orientation change
   */
  $(window).on('orientationchange', function () {
    setTimeout(function () {
      if ($('.menu-icon').hasClass('is-active') && window.innerWidth < 641) {
        $('[data-responsive-toggle="main-menu"]').foundation('toggleMenu');
      }
    }, 200);
  });

  resizeVideo();
});

/**
 * Scripts which runs after all elements load
 */
$(window).on('load', function () {
  // jQuery code goes here

  let $preloader = $('.preloader');
  if ($preloader.length) {
    $preloader.addClass('preloader--hidden');
  }
});

$(document).ready(function () {
  // $('.js-state-view').on('change', function (e) {
  //   e.preventDefault();
  //   const value = e.target.value;
  //   console.log(value);
  //   const map = $('.states-map-item');
  //   const list = $('.us-state-list');
  //   if (value === '1') {
  //     map.addClass('active');
  //     list.removeClass('active');
  //   } else {
  //     map.removeClass('active');
  //     list.addClass('active');
  //   }
  // });

  $('.js-state').on('mouseover', function () {
    const path = $(this);
    const stateId = path.attr('id');
    const popup = $('[data-state-id="' + stateId + '"]');
    $('.js-state-popup').removeClass('active');
    popup.addClass('active');
    // const container = $('.map-container');
    const pathElement = $(this)[0];
    // const pathBoundingBox = pathElement.getBBox();
    // const svgBoundingBox = container[0].getBoundingClientRect();

    // console.log('pathb y', pathBoundingBox.y);
    // const pathMidX = pathBoundingBox.x + pathBoundingBox.width / 2;
    // const pathMidY =
    //   pathBoundingBox.y + pathBoundingBox.height / 2 - 117 + 'px';
    const pathMidX =
      (
        $('.map-svg')[0].getBoundingClientRect().bottom -
        (pathElement.getBoundingClientRect().bottom -
          pathElement.getBoundingClientRect().height / 2)
      ).toFixed(0) + 'px';
    const pathMidY =
      (
        pathElement.getBoundingClientRect().left +
        pathElement.getBoundingClientRect().width / 2 -
        $('.map-svg')[0].getBoundingClientRect().left
      ).toFixed(0) + 'px';
    // console.log($('.map-svg')[0].getBoundingClientRect().bottom);

    // console.log(midXRelativeToContainer, midYRelativeToContainer);

    // Set the position of the info window
    // popup.style.bottom = pathMidX;
    // popup.style.left = pathMidY;
    // popup.css('top', midXRelativeToContainer);
    // popup.css('left', midYRelativeToContainer);
    popup.css({ bottom: pathMidX, left: pathMidY });
    // console.log(midXRelativeToContainer);
  });
  $('.map-container').on('mouseleave', function () {
    const path = $(this);
    const stateId = path.attr('id');
    const popup = $('[data-state-id="' + stateId + '"]');
    popup.removeClass('active');
  });
});

/**
 * Scripts which runs at window resize
 */
$(window).on('resize', function () {
  // jQuery code goes here

  resizeVideo();
});

$(document).ready(function () {
  $('#color_mode').on('change', function () {
    colorModePreview(this);
  });
});

function colorModePreview(ele) {
  const map = $('.states-map-item');
  const list = $('.us-state-list');
  if ($(ele).prop('checked') == true) {
    $('.body-btn').addClass('dark-preview');
    $('.body-btn').removeClass('white-preview');
    map.removeClass('active');
    list.addClass('active');
  } else if ($(ele).prop('checked') == false) {
    $('.body-btn').addClass('white-preview');
    $('.body-btn').removeClass('dark-preview');
    map.addClass('active');
    list.removeClass('active');
  }
}
/**
 * Scripts which runs on scrolling
 */
$(window).on('scroll', function () {
  // jQuery code goes here
});
