import $ from 'jquery';

$(document).ready(function () {
  $('.js-state-view').on('change', function (e) {
    e.preventDefault();
    const value = e.target.value;
    const map = $('.states-map-item');
    const list = $('.state-list');
    if (value === 'map') {
      map.addClass('active');
      list.removeClass('active');
    } else {
      map.removeClass('active');
      list.addClass('active');
    }
  });
});

// function acfExampleBlock() {
//   $('.js-acf-example-block').each(function () {});
// }
