/* eslint-disable no-undef */
/* global ajax_object */
/**
 * File: blog-filter.js
 *
 * Manage our portfolio filter.
 */
$(document).ready(function () {
  // $('.desktop-filter').on('click', '.filter-item > a', function (e) {
  //   e.preventDefault();
  //   $('.filter-item > a').removeClass('active-category');
  //   $(this).addClass('active-category');
  //   let category = $(this).data('category');
  //   const taxonomy = $(this).closest('.desktop-filter').data('tax');
  //   const postType = $(this).closest('.desktop-filter').data('post-type');
  //   if (category === 'all') {
  //     category = '';
  //   }
  //   handleFilterChange(category, taxonomy, postType);
  // });

  function getUrlParam(param) {
    let urlParams = new URLSearchParams(window.location.search);
    return urlParams.get(param);
  }

  function handleAjaxFilter(category, taxonomy, postType) {
    $.ajax({
      type: 'POST',
      url: ajax_object.ajax_url,
      data: {
        action: 'handle_ajax_filter',
        filter_category: category,
        filter_tax: taxonomy,
        filter_postType: postType,
        paged: 1,
      },
      beforeSend: function () {
        console.log('Loading, 5s.');
      },
      success: function (response) {
        $('.blog-container').html(response);
      },
    });
  }

  let initialCategory = getUrlParam('cat');

  if (initialCategory) {
    handleAjaxFilter(initialCategory, 'category', 'post');
    $('.filter-item > a').removeClass('active-category');
    $('.filter-item > a').each(function () {
      if ($(this).data('category') === Number(initialCategory)) {
        $(this).addClass('active-category');
      }
    });
  }

  $('.mobile-filter').on('change', '.filter-select', function () {
    let selectedOption = $(this).find(':selected');
    let category = selectedOption.val();
    let taxonomy = $(this).data('tax');
    let postType = $(this).data('post-type');
    handleFilterChange(category, taxonomy, postType);
  });

  function handleFilterChange(category, taxonomy, postType) {
    $.ajax({
      type: 'POST',
      url: ajax_object.ajax_url,
      data: {
        action: 'handle_ajax_filter',
        filter_category: category,
        filter_tax: taxonomy,
        filter_postType: postType,
        paged: 1, // Reset to the first page when filtering
      },
      beforeSend: function () {
        console.log('Loading...');
      },
      success: function (response) {
        $('.blog-container').html(response); // Assuming you have a container div with class 'blog-container'
      },
      error: function (error) {
        console.log(error);
      },
    });
  }

  $('.filter-item > a').on('click', function (e) {
    e.preventDefault();
    $('.filter-item > a').removeClass('active-category');
    $(this).addClass('active-category');
    let category = $(this).data('category');
    const taxonomy = $(this).closest('.filter-group').data('tax');
    const postType = $(this).closest('.filter-group').data('post-type');
    if (category === 'all') {
      category = '';
    }
    $.ajax({
      type: 'POST',
      url: ajax_object.ajax_url,
      data: {
        action: 'handle_ajax_filter',
        filter_category: category,
        filter_tax: taxonomy,
        filter_postType: postType,
        paged: 1,
      },
      beforeSend() {
        console.log('Loading, 5s.');
      },
      success(response) {
        $('.blog-container').html(response);
      },
    });
  });

  // Add event listener for pagination links
  $(document).on('click', '.pagination-1 a', function (e) {
    e.preventDefault();
    let page = $(this).text(); // Extract page number from link
    if ($(this).hasClass('next')) {
      page = parseInt($('.pagination .current').text()) + 1;
    }
    if ($(this).hasClass('prev')) {
      page = parseInt($('.pagination .current').text()) - 1;
    }
    const category =
      $('.filter-item > a.active-category').data('category') || '';
    const taxonomy = $('.filter-item > a.active-category')
      .closest('.filter-group')
      .data('tax');
    const postType = $('.filter-item > a.active-category')
      .closest('.filter-group')
      .data('post-type');

    $.ajax({
      type: 'POST',
      url: ajax_object.ajax_url,
      data: {
        action: 'handle_ajax_filter',
        filter_category: category,
        filter_tax: taxonomy,
        filter_postType: postType,
        paged: page,
      },
      beforeSend() {
        // /* eslint-disable no-console */
        // console.log( page );
        // /* eslint-enable no-console */
        // /* eslint-disable no-console */
        // console.log( postType );
        // /* eslint-enable no-console */
        // /* eslint-disable no-console */
        // console.log( taxonomy );
        // /* eslint-enable no-console */
        // /* eslint-disable no-console */
        // console.log( category );
        // /* eslint-enable no-console */
      },
      success(response) {
        $('.blog-container').html(response);
      },
    });
  });
});
